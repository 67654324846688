exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-desarrollo-cuautitlan-i-jsx": () => import("./../../../src/pages/desarrollo/cuautitlan-I.jsx" /* webpackChunkName: "component---src-pages-desarrollo-cuautitlan-i-jsx" */),
  "component---src-pages-desarrollo-lerma-i-jsx": () => import("./../../../src/pages/desarrollo/lerma-I.jsx" /* webpackChunkName: "component---src-pages-desarrollo-lerma-i-jsx" */),
  "component---src-pages-desarrollo-monterrey-i-jsx": () => import("./../../../src/pages/desarrollo/monterrey-I.jsx" /* webpackChunkName: "component---src-pages-desarrollo-monterrey-i-jsx" */),
  "component---src-pages-desarrollo-monterrey-ii-jsx": () => import("./../../../src/pages/desarrollo/monterrey-II.jsx" /* webpackChunkName: "component---src-pages-desarrollo-monterrey-ii-jsx" */),
  "component---src-pages-desarrollo-styled-id-js": () => import("./../../../src/pages/desarrollo/StyledId.js" /* webpackChunkName: "component---src-pages-desarrollo-styled-id-js" */),
  "component---src-pages-desarrollo-tlalnepantla-i-jsx": () => import("./../../../src/pages/desarrollo/tlalnepantla-I.jsx" /* webpackChunkName: "component---src-pages-desarrollo-tlalnepantla-i-jsx" */),
  "component---src-pages-desarrollo-tlalnepantla-ii-jsx": () => import("./../../../src/pages/desarrollo/tlalnepantla-II.jsx" /* webpackChunkName: "component---src-pages-desarrollo-tlalnepantla-ii-jsx" */),
  "component---src-pages-desarrollo-tlalnepantla-iii-jsx": () => import("./../../../src/pages/desarrollo/tlalnepantla-III.jsx" /* webpackChunkName: "component---src-pages-desarrollo-tlalnepantla-iii-jsx" */),
  "component---src-pages-desarrollo-tlalnepantla-iv-jsx": () => import("./../../../src/pages/desarrollo/tlalnepantla-IV.jsx" /* webpackChunkName: "component---src-pages-desarrollo-tlalnepantla-iv-jsx" */),
  "component---src-pages-desarrollo-tultepec-i-jsx": () => import("./../../../src/pages/desarrollo/tultepec-I.jsx" /* webpackChunkName: "component---src-pages-desarrollo-tultepec-i-jsx" */),
  "component---src-pages-desarrollo-tultitlan-i-jsx": () => import("./../../../src/pages/desarrollo/tultitlan-I.jsx" /* webpackChunkName: "component---src-pages-desarrollo-tultitlan-i-jsx" */),
  "component---src-pages-desarrollo-tultitlan-ii-jsx": () => import("./../../../src/pages/desarrollo/tultitlan-II.jsx" /* webpackChunkName: "component---src-pages-desarrollo-tultitlan-ii-jsx" */),
  "component---src-pages-desarrollo-tultitlan-iii-jsx": () => import("./../../../src/pages/desarrollo/tultitlan-III.jsx" /* webpackChunkName: "component---src-pages-desarrollo-tultitlan-iii-jsx" */),
  "component---src-pages-desarrollos-jsx": () => import("./../../../src/pages/desarrollos.jsx" /* webpackChunkName: "component---src-pages-desarrollos-jsx" */),
  "component---src-pages-estado-[id]-jsx": () => import("./../../../src/pages/estado/[id].jsx" /* webpackChunkName: "component---src-pages-estado-[id]-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */)
}

